import { Image, Row } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { Button } from "../../common/Button";
import { LogoContainer, NotHidden, CustomNavLinkSmall, Span } from "./styles";
import { memo, useEffect, useState } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import { useHistory, useLocation } from "react-router-dom";

const Header = ({ t }: any) => {
  const {pathname} = useLocation()
  const history = useHistory()
  
  const [enableShadow, setEnableShadow] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setEnableShadow(true);
      } else {
        setEnableShadow(false);
      }
    };
  }, []);

  const handleScroll = (id: any)=>{
    const element = document.getElementById(id) as HTMLDivElement;
    const y = element.getBoundingClientRect().top + window.pageYOffset - 150;
    window.scrollTo({top: y, behavior: 'smooth'});

  }

  const scrollTo = (id: string) => {
    // @ts-ignore
    if((pathname !== '/' || pathname !== '/home') && id === 'about'){
      history.push('/')
      setTimeout(() => {
        handleScroll(id)
      }, 100);
      return;
    }
    handleScroll(id)
  };
  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("footer")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <Fade top>
      <div
        style={{
          backgroundColor: "#fff",
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          boxShadow: enableShadow ? "0 1px 2px rgba(0,0,0,0.3)" : "",
        }}
      >
        <Container>
          <Row justify="space-between">
            <LogoContainer to="/" aria-label="homepage">
              <Row align="middle">
                <Image preview={false} width={80} height={80} src={"/img/icons/logo192.png"} />
              </Row>
            </LogoContainer>
            <NotHidden>
              <MenuItem />
            </NotHidden>
          </Row>
        </Container>
      </div>
    </Fade>
  );
};

export default withTranslation()(memo(Header));
