import { Row, Col, Typography, Image } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Large,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import { PRIMARY_COLOR } from "../../common/Colors";

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between" id='footer'>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Row align="middle">
              <SvgIcon
                  src="facebook.png"
                  aria-label="homepage"
                  width="20px"
                  height="20px"
                />
                <a href="https://www.facebook.com/languageking.official">
                  <Typography.Text  style={{ marginLeft: 8, color: PRIMARY_COLOR }}>Language King</Typography.Text>
                </a>
              </Row>
              <br />
              <Row align="middle">
                <SvgIcon
                  src="email.png"
                  aria-label="homepage"
                  width="20px"
                  height="20px"
                />
                <a href="mailto:support@language-king.com">
                  <Typography.Text style={{ marginLeft: 8 }}>support@language-king.com</Typography.Text>
                </a>
              </Row>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Legal")}</Title>
              <Large to="/terms" left="true">
                {t("Terms")}
              </Large>
              <Large to="/privacy" left="true">
                {t("Privacy Policy")}
              </Large>
            </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("vi")}>
                  <SvgIcon
                    src="vietnam.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <Row align="middle">
                <Image  preview={false} style={{ borderRadius: 8}} width={40} height={40}  src={"/img/icons/logo192.png"} />
                <Typography.Text style={{ marginLeft: 20, color: PRIMARY_COLOR }}>
                 Language King
              </Typography.Text>
                </Row>
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <Typography.Text style={{ width:'100%', fontSize: 12, color: "gray" }}>
                 © 2021 minhchienwikipedia. All rights reserved
              </Typography.Text>
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
